@font-face {
  font-family: 'Metropolis-Black';
  src: url('Metropolis-Black.eot');
  src: url('Metropolis-Black.eot?#iefix') format('embedded-opentype'),
    url('Metropolis-Black.svg#Metropolis-Black') format('svg'),
    url('Metropolis-Black.ttf') format('truetype'),
    url('Metropolis-Black.woff') format('woff'),
    url('Metropolis-Black.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}
