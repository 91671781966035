@font-face {
  font-family: 'Metropolis-Bold';
  src: url('Metropolis-Bold.eot');
  src: url('Metropolis-Bold.eot?#iefix') format('embedded-opentype'),
    url('Metropolis-Bold.svg#Metropolis-Bold') format('svg'),
    url('Metropolis-Bold.ttf') format('truetype'),
    url('Metropolis-Bold.woff') format('woff'),
    url('Metropolis-Bold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}
