@font-face {
  font-family: 'Metropolis-Medium';
  src: url('Metropolis-Medium.eot');
  src: url('Metropolis-Medium.eot?#iefix') format('embedded-opentype'),
    url('Metropolis-Medium.svg#Metropolis-Medium') format('svg'),
    url('Metropolis-Medium.ttf') format('truetype'),
    url('Metropolis-Medium.woff') format('woff'),
    url('Metropolis-Medium.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}
