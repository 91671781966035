@font-face {
  font-family: 'Metropolis-SemiBold';
  src: url('Metropolis-SemiBold.eot');
  src: url('Metropolis-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('Metropolis-SemiBold.svg#Metropolis-SemiBold') format('svg'),
    url('Metropolis-SemiBold.ttf') format('truetype'),
    url('Metropolis-SemiBold.woff') format('woff'),
    url('Metropolis-SemiBold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}
