@font-face {
  font-family: 'Metropolis-ExtraLight';
  src: url('Metropolis-ExtraLight.eot');
  src: url('Metropolis-ExtraLight.eot?#iefix') format('embedded-opentype'),
    url('Metropolis-ExtraLight.svg#Metropolis-ExtraLight') format('svg'),
    url('Metropolis-ExtraLight.ttf') format('truetype'),
    url('Metropolis-ExtraLight.woff') format('woff'),
    url('Metropolis-ExtraLight.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}
