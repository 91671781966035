@font-face {
  font-family: 'Metropolis-Regular';
  src: url('Metropolis-Regular.eot');
  src: url('Metropolis-Regular.eot?#iefix') format('embedded-opentype'),
    url('Metropolis-Regular.svg#Metropolis-Regular') format('svg'),
    url('Metropolis-Regular.ttf') format('truetype'),
    url('Metropolis-Regular.woff') format('woff'),
    url('Metropolis-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}
