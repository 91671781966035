@font-face {
  font-family: 'Metropolis-Thin';
  src: url('Metropolis-Thin.eot');
  src: url('Metropolis-Thin.eot?#iefix') format('embedded-opentype'),
    url('Metropolis-Thin.svg#Metropolis-Thin') format('svg'),
    url('Metropolis-Thin.ttf') format('truetype'),
    url('Metropolis-Thin.woff') format('woff'),
    url('Metropolis-Thin.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}
