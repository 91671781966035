@font-face {
  font-family: 'Metropolis-ExtraBold';
  src: url('Metropolis-ExtraBold.eot');
  src: url('Metropolis-ExtraBold.eot?#iefix') format('embedded-opentype'),
    url('Metropolis-ExtraBold.svg#Metropolis-ExtraBold') format('svg'),
    url('Metropolis-ExtraBold.ttf') format('truetype'),
    url('Metropolis-ExtraBold.woff') format('woff'),
    url('Metropolis-ExtraBold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}
