@import '~bootstrap/scss/bootstrap.scss';

@import url('https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Vujahday+Script&display=swap');

// Metropolis
@import './common/fonts/Metropolis-Black/Metropolis-Black.css';
@import './common/fonts/Metropolis-Bold/Metropolis-Bold.css';
@import './common/fonts/Metropolis-ExtraBold/Metropolis-ExtraBold.css';
@import './common/fonts/Metropolis-ExtraLight/Metropolis-ExtraLight.css';
@import './common/fonts/Metropolis-Light/Metropolis-Light.css';
@import './common/fonts/Metropolis-Medium/Metropolis-Light.css';
@import './common/fonts/Metropolis-Regular/Metropolis-Regular.css';
@import './common/fonts/Metropolis-SemiBold/Metropolis-SemiBold.css';
@import './common/fonts/Metropolis-Thin/Metropolis-Thin.css';

$primaryColor: #2f80ed;
$white: #ffffff;
$primaryFont: 'Poppins', sans-serif;

a,
a:hover {
  text-decoration: none;
}

body {
  margin: 0;
  font-family: $primaryFont;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .input-field {
    font-size: 22px;
  }

  .MuiCheckbox-colorPrimary,
  .MuiRadio-colorPrimary {
    &.Mui-checked {
      color: $primaryColor;
    }
  }

  .sendbird-incoming-og-message {
    .sendbird-incoming-og-message--inner {
      .sendbird-incoming-og-message--body {
        .sendbird-incoming-og-message__text-balloon {
          word-break: normal;
          text-align: left;
          display: flex;
          flex-wrap: wrap;
        }
      }
    }
  }

  &.sendbird-theme--light {
    .sendbird-channel-header {
      .sendbird-channel-header__right-icon {
        display: none;
      }

      .sendbird-channel-header__title {
        .sendbird-channel-header__title--name,
        .sendbird-channel-header__title--user-id {
          overflow: hidden;
        }
      }
    }

    .sendbird-channel-preview__action,
    .sendbird-channel-preview__content__upper__header__total-members {
      display: none;
    }

    .sendbird-loader {
      color: $primaryColor;

      svg {
        path {
          fill: $primaryColor;
        }
      }
    }

    .sendbird-channel-list__body {
      height: calc(100vh - 173px);
      overflow-y: auto;
    }

    .sendbird-app__conversation-wrap {
      max-height: calc(100vh - 100px);
      min-width: 350px;
    }

    .sendbird-conversation__messages {
      height: calc(100vh - 234px);
    }

    .sendbird-conversation {
      min-height: calc(100vh - 82px);

      .sendbird-notification {
        background: $primaryColor;
        display: none;
      }
    }

    .sendbird-app__wrap {
      min-height: calc(100vh - 82px);
    }

    .sendbird-app__channellist-wrap {
      .sendbird-channel-preview--active {
        border-left: solid 4px $primaryColor;
        background: rgba($color: $primaryColor, $alpha: 0.2);

        .sendbird-channel-preview__content__upper__header {
          .sendbird-label--color-onbackground-1 {
            color: $primaryColor;
          }
        }
      }
    }

    .sendbird-color--primary {
      color: $primaryColor;

      [class*='fill'] {
        fill: $primaryColor;
      }
    }

    .sendbird-user-message--outgoing {
      .sendbird-user-message__text-balloon {
        background: rgba($color: $primaryColor, $alpha: 0.2) !important;
        border-radius: 22px;
      }
    }

    .sendbird-badge {
      background: $primaryColor;
      display: flex;
      align-items: center;
      justify-content: center;

      .sendbird-badge__text {
        margin: 0;
      }
    }

    .sendbird-label--color-oncontent-1 {
      line-height: normal;
    }

    .sendbird-channel-header__title--right {
      display: flex;
      align-items: center;
      padding: 0px;
    }

    .sendbird-channel-header__title--right .sendbird-label--body-2 {
      display: none !important;
    }
  }

  .MuiFormLabel-root.Mui-focused {
    color: $primaryColor;
  }

  .MuiInput-underline:after {
    border-bottom: 2px solid $primaryColor;
  }

  .search-field {
    background-repeat: no-repeat;
    background-position: right center;

    .MuiInputBase-root.MuiFilledInput-root {
      background-color: transparent;
    }

    input {
      padding-right: 25px;
    }
  }

  .MuiBadge-colorPrimary {
    background-color: $primaryColor;
    right: -8px;
  }
}

.MuiMenu-paper {
  max-height: 250px;
}

a,
button,
input {
  outline: none !important;
}

.focusRing___1airF {
  display: none;
}

#welcome-to-game-plan {
  padding: 0;
}

h1 {
  font-size: 50px;
  font-weight: 600;
  letter-spacing: -0.05em;
  margin: 0 0px 25px;

  @media only screen and (max-width: 1200px) {
    font-size: 28px;
  }
}

@media only screen and (max-width: 767px) {
  #intercom-container {
    display: none;
  }

  .intercom-launcher {
    display: none;
  }

  .MuiDialog-paper {
    margin: 20px !important;
  }

  .MuiDialog-paperFullWidth {
    width: calc(100% - 30px) !important;
  }

  #menu-essayTemplate {
    li {
      span {
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
}

@media only screen and (max-width: 479px) {
  .hide-first-col-mobile {
    tr {
      th,
      td {
        &:first-child {
          display: none;
        }
      }
    }
  }
}

div[aria-labelledby='simple-modal-deleteCard']
  + div[aria-labelledby='simple-modal-deleteCard'],
div[aria-labelledby='simple-modal-title']
  + div[aria-labelledby='simple-modal-title'] {
  opacity: 0;
}

#timeZoneDialog {
  .MuiDialog-paperWidthSm {
    width: 100%;
    max-width: 550px;
  }
}

.time-zone-content {
  & input {
    border: 0px !important;
    height: 40px;
    width: 100%;
    font-size: 14px;
    color: #272929;
    padding: 8px 40px 8px 16px;
    font-family: 'Inter', sans-serif;
  }

  & .forLabelColor input {
    opacity: 0.7;
    background-color: #fff;
    font-family: 'Inter', sans-serif;
  }

  #mui-component-select-year {
    font-family: 'Inter', sans-serif;
  }

  // & .Mui-focused {
  //   input {
  //     opacity: 0;
  //   }
  // }

  & fieldset {
    display: block !important;
  }

  & label {
    font-size: 16px;
    transform: translate(14px, 21px) scale(1) !important;
  }
}

.dialog-actions {
  padding: 10px 50px !important;
  border-top: 0px !important;
}

// element slide fade
.next-enter {
  opacity: 0;
  transform: translateX(-100%);
}

.next-enter-active {
  opacity: 1;
  transform: translateX(0%);
}

.next-exit {
  opacity: 1;
  transform: translateX(0%);
}

.next-exit-active {
  opacity: 0;
  transform: translateX(100%);
}

.next-enter-active,
.next-exit-active {
  transition: opacity 500ms, transform 500ms;
}

.prev-enter {
  opacity: 0;
  transform: translateX(100%);
}

.prev-enter-active {
  opacity: 1;
  transform: translateX(0%);
}

.prev-exit {
  opacity: 1;
  transform: translateX(0%);
}

.prev-exit-active {
  opacity: 0;
  transform: translateX(-100%);
}

.prev-enter-active,
.prev-exit-active {
  transition: opacity 500ms, transform 500ms;
}

.removeArrow {
  display: none;
}

.removeDisplay {
  display: initial !important;
}

.webinarCarousel .react-multiple-carousel__arrow {
  display: block !important;
}

.webinarCarousel .react-multiple-carousel__arrow--right,
.webinarCarousel .react-multiple-carousel__arrow--left {
  background-color: rgba(0, 0, 0, 0.5) !important;
  background-repeat: no-repeat !important;
  background-size: 30% !important;
  height: 100%;
}

.webinarCarousel .react-multiple-carousel__arrow--left {
  background-image: url('./img/back.svg') !important;
  background-position: 10px !important;
  left: calc(0% + 4px) !important;
  border-radius: 7px 0px 0px 7px !important;
  background-repeat: no-repeat !important;
}

.webinarCarousel .react-multiple-carousel__arrow--right {
  background-image: url('./img/next.svg') !important;
  background-position: 17px !important;
  right: calc(0% + 5px) !important;
  border-radius: 0px 7px 7px 0px !important;
  background-repeat: no-repeat !important;
}

.webinarCarousel .react-multiple-carousel__arrow--left,
.webinarCarousel .react-multiple-carousel__arrow--right {
  &:before {
    display: none !important;
  }
}

// time slot scroll custom
.availableTimeScroll {
  height: 280px;
  overflow: auto;
  margin-top: 15px;
}

.timeZoneSelectCustom {
  .select__menu-list {
    .Mui-selected {
      &:hover {
        background-color: rgba(0, 0, 0, 0.04) !important;
      }
    }
  }
}
.availableTimeScroll::-webkit-scrollbar-track,
.collegeSearchScroll::-webkit-scrollbar-track {
  visibility: hidden;
}

.availableTimeScroll::-webkit-scrollbar,
.collegeSearchScroll::-webkit-scrollbar {
  visibility: hidden;
}

.availableTimeScroll::-webkit-scrollbar-thumb,
.collegeSearchScroll::-webkit-scrollbar-thumb {
  visibility: hidden;
}

.enrollmentTabScroll::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #eaeaea;
}

.enrollmentTabScroll::-webkit-scrollbar {
  height: 9px;
  background-color: transparent;
}

.enrollmentTabScroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #a9a9a9;
}

.errorMessage {
  color: red;
}

#sidebar {
  transition: top 0.5s ease-in-out;
}

#sidebar.sticky {
  position: sticky;
  top: 21px;
}

.MuiFormHelperText-root {
  color: red;
}

select {
  -webkit-appearance: none;
}

#sidebar {
  transition: top 0.5s ease-in-out;
}

#sidebar.sticky {
  position: sticky;
  top: 21px;
}

.overlayText {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.overlayTextSmall {
  -webkit-line-clamp: 3;
}

.overlayTextHub {
  -webkit-line-clamp: 2;
}

.MuiMenuItem-root.statusMenu {
  font-weight: 600 !important;
  font-size: 14px !important;
  font-family: 'Poppins', sans-serif !important;
  letter-spacing: -0.006em !important;
  text-transform: uppercase !important;
}

.stuck {
  color: #fdb515 !important;
}

.reviewReady {
  color: #992fed !important;
}

.inProgress {
  color: #2f80ed !important;
}

.notStarted {
  color: #a9a9a9 !important;
}

// .complete,
.Completed {
  color: #45ce93 !important;
}

.menuButton {
  width: 30px !important;
  height: 30px !important;
  padding: 4px !important;
  margin-left: 14px !important;
}

.detailLineCLamp {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.detailLineCLampHub {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@media only screen and (max-width: 767px) {
  .commonCoaliationEssayScroll {
    height: 280px;
    overflow: auto;
  }
}

.commonCoaliationEssayScroll {
  margin-top: 15px;
  padding-right: 22px !important;
}

.calendlyInfoFormForEmailScroll {
  padding: 0px !important;
}

.organizationTitle,
.opportunityTitle {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.organizationTitle {
  -webkit-line-clamp: 1;
}

.opportunityTitle {
  -webkit-line-clamp: 2;
}

.requiredText {
  color: red;
  font-size: 11px;
  font-family: 'Poppins', sans-serif !important;
}

.collegeNameClamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.requiredText {
  color: red;
  font-size: 12px;
}

.MuiBackdrop-root {
  background-color: rgba(0, 0, 0, 0.1);
}

.collegeScrollContent {
  overflow-y: auto;
  padding: 0px 15px 0px 0px;

  a {
    font-family: 'Inter', sans-serif !important;
    font-size: 14px;
  }
}

.collegeScroll {
  height: 265px;
}

.collegeScrollPadding {
  height: 350px;
}

.collegeScroll::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #eaeaea;
}

.collegeScroll::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;
}

.collegeScroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #a9a9a9;
}

.reviewDocumentArea::-webkit-scrollbar-track {
  border-radius: 50px;
  background-color: #d4d4d4;
}

.reviewDocumentArea::-webkit-scrollbar {
  width: 11px;
  background-color: transparent;
}

.reviewDocumentArea::-webkit-scrollbar-thumb {
  border-radius: 100px;
  background-color: #eaeaea;
}

.planBillingTableResponsive {
  @media only screen and (max-width: 1279px) {
    width: 100%;
    overflow-x: auto;
  }
}

.dialogScroll {
  @media only screen and (max-height: 600px) {
    height: 500px;
    overflow-x: auto;
  }
}

.phoneInputMasterResolve {
  .PhoneInput {
    display: flex;
    align-items: center;
  }

  .PhoneInputCountry {
    position: relative;
    align-self: stretch;
    display: flex;
    align-items: center;
    margin-right: 0.35em;
  }

  .PhoneInputCountrySelect {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    border: 0;
    opacity: 0;
    cursor: pointer;
  }

  .PhoneInputCountryIcon {
    color: #62636b;
  }

  .PhoneInputCountryIcon--border {
    background-color: rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5), inset 0 0 0 1px rgba(0, 0, 0, 0.5);
  }

  .PhoneInputCountryIcon {
    width: 1.5em;
    height: 1em;
  }

  .PhoneInputCountrySelectArrow {
    border: 1px solid #1b1b1b !important;
    border-color: #1b1b1b;
    border-top-width: 0px !important;
    border-left-width: 0px !important;
  }

  .PhoneInputCountryIconImg {
    display: block;
    width: 100%;
    height: 100%;
  }

  .PhoneInputInput {
    color: #62636b;
    border: 1px solid #62636b;
    height: 33px;
    padding: 0 10px;
    font-size: 14px;
    background: #ffffff;
    border-radius: 5px !important;
  }

  .PhoneInputInput {
    flex: 1 1;
    min-width: 0;
  }

  label {
    top: -17px;
    left: 37px !important;
    color: #62636b;
    position: absolute;
    font-size: 10px;
    transform: none !important;
    font-family: Inter, sans-serif;
    font-weight: 500;
  }

  select {
    -webkit-appearance: none;
  }

  .PhoneInputCountrySelectArrow {
    display: block;
    content: '';
    width: 0.3em;
    height: 0.3em;
    margin-left: 0.35em;
    border-color: inherit;
    border-bottom-width: 1px;
    border-right-width: 1px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    opacity: 0.45;
  }
}

#filterHeader,
#pageHeaderSticky,
#collegeSearchFilter {
  transition: top 0.7s ease-in-out;
}

.stickySidebar {
  position: sticky;
  top: 170px;
  height: calc(100vh - 225px) !important;
}

.collegeSearchScroll {
  padding-right: 15px;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh;

  &:hover {
    &::-webkit-scrollbar-track,
    &::-webkit-scrollbar,
    &::-webkit-scrollbar-thumb {
      visibility: visible;
    }
  }
}

#filterHeader {
  position: sticky;
  top: 95px;
  z-index: 9;
}

#filterHeaderMobile {
  position: sticky;
  top: 0px;
  z-index: 999;
}

#pageHeaderSticky {
  position: sticky;
  top: 0px;
  z-index: 999;

  @media only screen and (max-width: 959px) {
    position: initial;
  }
}

.errorMessage p {
  color: #e94444;
  font-weight: 400;
  font-size: 12px;
  font-family: 'Inter', sans-serif;
  margin-top: 4px;
}

#orderSummary {
  transition: top 0.5s ease-in-out;

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
}

#orderSummary.sticky {
  position: sticky;
  top: 21px;
}

.bioText {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.editProfilePicture {
  label {
    font-weight: 400;
    font-size: 12px;
    letter-spacing: -0.006em;
    color: #2f80ed;
    margin: 0px;
    line-height: 16px;
    border-bottom: 1px solid #2f80ed;
    cursor: pointer;
    font-family: 'Inter', sans-serif;
  }

  input[type='file'] {
    display: none;
  }
}

.userProfileName {
  width: 100%;
}

.mobileCollegeName {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.blogText {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.descriptionReviewText,
.bioTextAdvisor {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.profileIndicateBadge {
  top: 1px;
  right: 1px;
  position: absolute;
}

.profileIndicateBadge,
.profileLinkBadge {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin: 0px 0px 0px 10px;
  background-color: #2f80ed;
}

.profileIndicateBadgeYellow {
  background-color: #fdbd4e !important;
}

.coursesSelect {
  .select__control {
    &:hover {
      background-color: #f4f4f4;
    }
  }

  .select__menu {
    margin: 2px 0px;
  }

  .select__value-container {
    min-height: 80px;
    padding: 12px 10px;
    align-items: baseline;
  }

  .select__clear-indicator,
  .select__dropdown-indicator,
  .select__indicator-separator {
    display: none !important;
  }

  .select__multi-value {
    border-radius: 4px !important;
    background: #2668c1 !important;
  }

  .select__placeholder {
    top: 32%;
  }

  .select__multi-value__remove {
    svg {
      width: 20px;
      height: 20px;
    }
  }

  .select__placeholder {
    color: #a9a9a9;
  }
}

.coursesSelectHeight {
  .select__value-container {
    min-height: 40px;
    padding: 3px 10px;
  }

  .select__placeholder {
    top: 49%;
  }

  .select__dropdown-indicator {
    display: block !important;

    & svg {
      color: #7d7f7f;
      width: 14px;
    }
  }
}

.coursesSelect.coursesProfile {
  .select__multi-value {
    background-color: #52c9bb !important;
  }
  .select__value-container {
    min-height: 40px;
    padding: 3px 10px;
  }

  .select__dropdown-indicator {
    display: block !important;

    & svg {
      color: #7d7f7f;
      width: 14px;
    }
  }

  .select__placeholder {
    top: 49%;
  }

  .select__multi-value__label {
    font-weight: 600;
    font-size: 14px;
    color: #ebf3fe;
    letter-spacing: -0.006em;
    font-family: 'Inter', sans-serif !important;
  }
}

.paddingBottom {
  line-height: 24px;
  padding-bottom: 0px !important;
}

p.fieldCaptionText {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 4px;
  letter-spacing: -0.006em;

  strong {
    font-weight: 400;
  }
}

textarea.textareaScroll {
  min-height: 135px;
}

p.multiSelectCaption {
  font-weight: 400;
  font-size: 12px;
  color: #62636b;
}

p.fieldCaptionTextSpacing {
  margin-top: 15px;
}

p.multiSelectCaption.multiSelectCaptionSpacing {
  padding-bottom: 19px !important;
}

.containerColumnBox {
  width: 30%;
  margin-right: 15px;
  display: inline-table;
}

.timeZoneWidth {
  width: 100%;
  max-width: 342px;
}

.locationColumnBox:first-child {
  width: 286px;
}

.scrollGridHeight {
  max-height: 430px;
  overflow-y: auto;
}

.extracurricularsScrollGrid {
  margin-top: 25px;
  padding: 0px 20px 30px 0px;

  h5 {
    font-size: 12px;
    font-weight: 600;
    color: #272929;
    letter-spacing: -0.006em;
  }

  h6 {
    color: #7d7f7f;
    margin-bottom: 3px;
  }

  p {
    color: #525454;
    margin-top: 10px;
  }

  h6,
  p {
    font-weight: 400;
    font-size: 12px;
    letter-spacing: -0.006em;
  }

  h5,
  h6,
  p {
    font-family: 'Inter', sans-serif;
    text-transform: initial;
  }

  .activitiesDivider {
    margin: 26px 0px;
    background-color: #d4d4d4;
  }
}

.disableText {
  opacity: 0.5 !important;
}

.placeholderText div:first-child {
  color: #a9a9a9 !important;
}

.valueText div {
  color: #272929;
}

.dangerButton {
  &:hover {
    background-color: #c63a3a !important;
  }

  &.Mui-disabled {
    opacity: 0.5;
    color: #fff !important;
    background-color: #e94444 !important;
  }
}

.spinner-border-sm {
  font-size: 10px !important;
}
.tooltipHoverButton {
  padding: 5px !important;
}

.spinner-border-sm {
  font-size: 10px;
}

button.iconButtonLink {
  min-width: 40px;
  width: 40px;
  height: 40px;
  padding: 5px !important;
  border-radius: 50%;

  img {
    margin: 0px;
  }
}

.MuiAlert-standardSuccess {
  background-color: #6bcc96 !important;

  & .MuiAlert-message {
    color: #ffffff;
  }

  & svg {
    path {
      fill: #ffffff !important;
    }
  }
}

button.acceptedButton {
  min-width: 198px !important;
  color: #45ce93 !important;
  border: 1px solid rgba(69, 206, 147, 0.5) !important;
}

@mixin for-phone-only {
  @media only screen and (max-width: 767px) {
    width: 100%;
  }
}

.selectAdvisorLevel {
  width: 262px;
  margin-right: 29px;
  @include for-phone-only();
}

.selectAreaInterest {
  width: 383px;
  @include for-phone-only();
}

.selectSpecialty {
  width: 316px;
  @include for-phone-only();
}

.selectLarge {
  width: 280px;
  @include for-phone-only();
}

.selectMedium {
  width: 210px;
  @include for-phone-only();
}

.selectSmall {
  width: 120px;
  @include for-phone-only();
}

.majorsSelect {
  .select__indicators {
    display: none;
  }
}

.customDropDown {
  border-radius: 6px;

  .select__control {
    font-size: 14px;
    min-height: 40px;
    border-radius: 6px;
    font-family: 'Inter', sans-serif;

    &.select__control--is-focused {
      border-color: #d4d4d4;
      box-shadow: none;
    }

    .select__value-container {
      .select__input {
        input {
          margin: 0px;
        }

        input[value~='University'] {
          color: white !important;
        }

        div {
          visibility: visible !important;
          overflow: initial !important;
          padding: 6px 10px !important;
        }
      }
    }
    &:hover {
      background-color: #f4f4f4;
    }
    &:active {
      background-color: #eaeaea;
    }
    .select__placeholder {
      color: #a9a9a9;
    }
  }
  .select__indicators {
    width: 20px;
    background-image: url('./img/SelectArrowDownIcon.svg') !important;
    background-repeat: no-repeat;
    background-position: center right 10px;
    .select__clear-indicator {
      position: relative;
      right: 30px;
    }
    .select__dropdown-indicator {
      svg {
        display: none;
      }
    }
    .select__indicator-separator {
      display: none;
    }
  }
  .select__menu {
    margin: 1px 0px;

    .select__menu-list {
      padding: 0px;
      border-left: 1px solid #d4d4d4;
      font-family: 'Inter', sans-serif;
      max-height: 300px;

      .select__option {
        font-size: 14px;
        padding: 5px 12px;
      }

      .select__option--is-focused {
        background-color: #f4f4f4;
      }
      .select__option--is-selected {
        color: rgba(0, 0, 0, 0.87);
        background-color: #eaeaea;
      }
    }
  }
}

.customDropDown.secondaryRanking,
.customDropDown.allRankingsDropdown {
  .select__option {
    font-size: 12px !important;
    padding: 1px 12px !important;
  }

  .select__menu-list {
    max-height: 400px;
  }
}

.customDropDown.allRankingsDropdown {
  .select__control {
    padding-left: 0px;
    background-position: center right 15px;
  }
}

.customDropDown.secondaryRanking {
  margin-top: 11px;
  .select__control {
    padding-left: 0px;
    background-position: center right 15px;
  }

  .select__control--is-disabled {
    background-color: #eaeaea;
    background-image: url(./img/disableArrowIcon.svg);

    .select__placeholder {
      color: #a9a9a9;
    }
  }
}

.hideCrossIcon {
  .select__clear-indicator {
    display: none;
  }
}

.sidebarSelect {
  .select__indicators {
    width: 25px;
    background-position: center right 15px;
  }
}

*::-webkit-scrollbar {
  width: 9px;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #a9a9a9;
}

.stickySidebar {
  .select__menu-list {
    padding: 0px;
    overflow-x: hidden;
  }
}

.goDashboard {
  border-radius: 5px;
  text-transform: capitalize !important;
}

.imgObjectFit {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.ellipsisText {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.languagesSelect {
  padding-top: 31px;
}

.dialogScroll {
  padding: 0px 42px;

  .react-select__menu-list {
    max-height: 200px;
  }

  @media only screen and (max-width: 600px) {
    height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0px 35px 0px 0px;
  }
}
